<template>
  <div class="container-fluid py-3">
    <div class="row justify-content-center">
      <div class="col-12">
        <h5 class="text-center">Premium</h5>
        <hr />
      </div>
      <div class="col-3 col-md-2" v-for="(premium, i) in premiums" :key="i">
        <div class="card">
          <img class="card-img-top" src="../../assets/images/premium.png" alt="Premiun" />
          <div class="card-body text-center">
            <h5
              class="py-2 mb-0"
            >{{ $tc('premiumForMonth', premium.month, {month: premium.month}) }}</h5>
            <h3 class="py-2 mb-0">$ {{ premium.price | money }} USD</h3>
            <b-button
              :disabled="!isAuthenticated"
              variant="primary"
              block
              :to="{ name: 'PremiumBuy', params: { lang: lang, console: console, month: premium.month } }"
            >{{ $t('toBuy') }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["console", "lang", "isAuthenticated"])
  },
  data() {
    return {
      premiums: window.premiums
    };
  }
};
</script>
